import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { privacy } from "../../urls";
import { device } from "../../utils/breakpoints";
import { colors } from "../../utils/theme";
import { transparentize } from "../../utils/helperFn";
import { Link } from "react-router-dom";
import { initializePostHog, resetPostHog } from "../../helpers/posthogManager";
import { initializeAnalytics } from "../../services/firebase";

const CookieBanner = () => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if user has already made a choice
    const consentStatus = localStorage.getItem("consentGranted");

    if (consentStatus === null) {
      // No decision made yet, show the banner
      setShowBanner(true);
    } else if (consentStatus === "true") {
      // User has previously accepted, initialize PostHog
      initializePostHog();
      initializeAnalytics();
    }
  }, []);

  const handleAccept = () => {
    // Store consent in localStorage
    localStorage.setItem("consentGranted", "true");

    // Initialize PostHog tracking
    initializePostHog();
    initializeAnalytics();

    // Hide the banner
    setShowBanner(false);
  };

  const handleDecline = () => {
    // Store decline in localStorage
    localStorage.setItem("consentGranted", "false");

    // Make sure PostHog is reset if it was previously initialized
    resetPostHog();

    // Hide the banner
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <CookieBannerWrapper>
      <Banner>
        <CookieIcon />
        <span>{t("cookieBar.cookieNotice")}</span>
        <ButtonsWrapper>
          <StyledLink to={privacy}>{t("cookieBar.readMoreBtn")}</StyledLink>
          <StyledButton className={"decline"} onClick={handleDecline}>
            {t("cookieBar.decline")}
          </StyledButton>
          <StyledButton onClick={handleAccept}>
            {t("cookieBar.accept")}
          </StyledButton>
        </ButtonsWrapper>
      </Banner>
    </CookieBannerWrapper>
  );
};

const CookieIcon = styled.div`
  display: none;

  @media ${device.lg} {
    display: block;
  }

  &:before {
    content: "🍪";
    font-size: 20px;
    @media ${device.md} {
      font-size: 30px;
    }
  }
`;

const StyledButton = styled(Button)`
  border-style: none;
  width: 100%;
  font-size: 14px;
  background: ${colors.cta_btn};
  border-radius: 24px;
  box-shadow:
    rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin: 5px 0;
  height: 35px;

  span {
    color: ${colors.cta_btn_text} !important;
    line-height: 20px;
  }

  &:hover,
  &:focus {
    background: ${transparentize(colors.cta_btn, 0.8)} !important;
    color: ${colors.cta_btn_text};
  }

  &.decline {
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.7);

    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 0.1) !important;
      border-color: white !important;
    }

    @media ${device.lg} {
      margin-right: 10px;
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  height: auto;
  color: rgb(181, 181, 181);
  padding: 10px 18px;
  border: none;
  box-shadow: none;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.5s ease 0s;

  &:focus,
  &:active {
    color: ${colors.primary};
  }

  @media ${device.md} {
    font-size: 14px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  opacity: 1;
  box-shadow:
    rgb(0 0 0 / 10%) 0px 24px 32px,
    rgb(0 0 0 / 10%) 0px 8px 32px;
  margin: 0 6px 48px;
  padding: 16px;
  border-radius: 32px;
  background: #3a3a3a;

  @media ${device.lg} {
    margin: 0 auto 48px;
  }

  span {
    color: #fff;
    font-size: 10px;
    margin: 0 20px;

    @media ${device.md} {
      font-size: 14px;
    }
  }
`;

const CookieBannerWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  width: 95%;
  z-index: 1201;
`;

export default CookieBanner;
