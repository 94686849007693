import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { load, save } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./translations/i18n";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { auth, db, firebaseConfig } from "./services/firebase";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import {
  AuthProvider,
  DatabaseProvider,
  FirebaseAppProvider,
  FirestoreProvider,
} from "reactfire";
import { PostHogProvider } from "posthog-js/react";
import { getPostHogClient } from "./helpers/posthogManager";

// Get the PostHog client (returns null if consent not granted)
const posthogClient = getPostHogClient();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save())),
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PostHogProvider client={posthogClient}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <AuthProvider sdk={auth}>
          <DatabaseProvider sdk={db}>
            <FirestoreProvider sdk={db}>
              <App />
            </FirestoreProvider>
          </DatabaseProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </PostHogProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
