import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export const DIGITAL_KISS = "Digital KISS";
export const DIGITAL_KISS_LOGO = "/assets/img/logo/logo-digitalkiss-text.png";
export const WEBSITE_NAME = "Bombyxx";
export const WEBSITE_SLOGAN = "Nature inspired";
export const WEBSITE_LOGO = "/assets/img/logo/logo.png";
export const WEBSITE_PHONE_NUMBER = "+31 615 908 333";
export const WEBSITE_EMAIL_ADDRESS = "info@bombyxx.com";
export const WEBSITE_STREET_NUMBER = "Industrieweg 12a";
export const WEBSITE_ZIPCODE = "1775PV";
export const WEBSITE_CITY = "Middenmeer";
export const GOOGLE_MAPS_LINK = "https://goo.gl/maps/tQtLkQJTyVTHR55x9";
export const GOOGLE_MAPS_LAT = "52.813972";
export const GOOGLE_MAPS_LON = "4.9910918";
export const SOCIAL_MEDIA = [
  // {
  //   icon: "fa fa-facebook",
  //   link: "https://www.facebook.com/bombyxxcollection/",
  //   name: "Facebook",
  // },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/bombyxx_collection/",
    name: "Instagram",
    value: "@bombyxx_collection",
  },
  // {
  //   icon: "fa fa-linkedin",
  //   link: "https://www.linkedin.com/company/bombyxx/",
  //   name: "LinkedIn",
  // },
];
export const LOGO_STORAGE_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Flogo.jpg?alt=media";
export const PRICE_RANGE_INDICATOR = "€";
