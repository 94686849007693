import { CURRENCY_CODE } from "../constants/DeliveryConstants";
import { getDiscountPriceWithDefault } from "./product";
import {
  ANALYTICS_ADD_TO_CART,
  ANALYTICS_SELECT_ITEM,
  RELATED_PRODUCTS_LIST_ID,
  RELATED_PRODUCTS_LIST_NAME,
} from "../constants/AnalyticsConstants";
import { initializeAnalytics } from "../services/firebase";
import { logEvent } from "firebase/analytics";

// Helper to get analytics instance or initialize it if not available
let analyticsInstance = null;

const getAnalytics = async () => {
  if (!analyticsInstance) {
    try {
      analyticsInstance = await initializeAnalytics();
    } catch (error) {
      console.warn("Analytics not initialized:", error);
      return null;
    }
  }
  return analyticsInstance;
};

// Helper function to log events safely
const safeLogEvent = async (eventName, eventParams) => {
  const analytics = await getAnalytics();
  if (analytics) {
    logEvent(analytics, eventName, eventParams);
  }
};

/**
 * Triggers select_item event when clicking on related product
 **/
export const setSelectRelatedProductAnalytics = async (product, index) => {
  await safeLogEvent(ANALYTICS_SELECT_ITEM, {
    item_list_id: RELATED_PRODUCTS_LIST_ID,
    item_list_name: RELATED_PRODUCTS_LIST_NAME,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: index,
        item_list_id: RELATED_PRODUCTS_LIST_ID,
        item_list_name: RELATED_PRODUCTS_LIST_NAME,
        price: product.price,
        quantity: 1,
      },
    ],
  });
};

/**
 * Triggers add_to_cart event when adding related product directly to cart
 **/
export const setAddToCartAnalyticsRelatedProducts = async (product, index) => {
  await safeLogEvent(ANALYTICS_ADD_TO_CART, {
    currency: CURRENCY_CODE,
    value: getDiscountPriceWithDefault(product.price, product.discount),
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: index,
        item_list_id: RELATED_PRODUCTS_LIST_ID,
        item_list_name: RELATED_PRODUCTS_LIST_NAME,
        price: product.price,
        quantity: 1,
      },
    ],
  });
};

/**
 * Dynamic analytics event for 1 specific item
 **/
export const setProductAnalyticsEvent = async (
  eventName,
  product,
  quantity,
) => {
  const value = getDiscountPriceWithDefault(product.price, product.discount);
  await safeLogEvent(eventName, {
    currency: CURRENCY_CODE,
    value: !!quantity ? value * quantity : value,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: 0,
        price: product.price,
        quantity: !!quantity ? quantity : 1,
      },
    ],
  });
};
